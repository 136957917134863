import React from 'react';
import { graphql } from 'gatsby';
import GameCreationRoot from '../../components/game-creation-root';
import BeDiceForm from '../../components/game-forms/be-dice-form';
import BeGameInstructions from '../../components/be-game-instructions';
import useLocalStorageState from 'use-local-storage-state'

const DiceGamePage = (props) => {
  const [experiment, setExperiment] = useLocalStorageState("experiment");
  const [dataUser, setDataUser] = React.useState();
  const handleOnChange = (event) => {
    dataUser[event.target.name] = event.target.value;
    setDataUser({...dataUser});
    console.log(dataUser);
  };

  React.useEffect(() => {
    setDataUser(props.location.state?.gamePosition !== undefined && experiment?.games[props.location.state.gamePosition].params || {
        currency: '$',
        value_1: 0.1,
        value_2: 0.2,
        value_3: 0.3,
        value_4: 0.4,
        value_5: 0.5,
        value_6: 0.0,
      }
    );
  }, [])
	return(
        <GameCreationRoot title={"Dice Game"}>
            <BeGameInstructions gameId={'dice'} dataUser={dataUser}  />
            <BeDiceForm handleOnChange={handleOnChange} />
        </GameCreationRoot>
	);
}

export default DiceGamePage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {ns: {in: ["common", "side_bar", "games", "form_errors"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;